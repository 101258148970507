import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color } from 'driverama-core/styles/variables'

export const SAuctionsDetailMenuWrapper = styled.div<{ isInHistory?: boolean }>`
  position: absolute;
  right: ${size(10)};
  top: ${size(10)};

  ${({ isInHistory }) =>
    !isInHistory &&
    css`
      @media ${media.lte('XL')} {
        display: none;
      }
    `}

  @media ${media.lte('laptop')} {
    top: ${size(9)};
    padding: ${size(3)} ${size(4)};
  }

  @media ${media.lte('tablet')} {
    display: none;
  }
`

export const SMenuItem = styled.button`
  text-align: left;
  padding: ${size(2)} ${size(4)};
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      background-color: ${color('almond-l-200')};
    }
  }
`
