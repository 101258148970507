import { css } from '@emotion/react'
import { Button } from 'driverama-core/components/button/Button'
import { Dropdown } from 'driverama-core/components/dropdown/Dropdown'
import { Flex } from 'driverama-core/components/Flex'
import { TextBody } from 'driverama-core/components/text/Text'
import IconChevronDown from 'driverama-core/images/icons/IconChevronDown.svg'
import { size } from 'driverama-core/styles/spacing'
import { useTranslation } from 'react-i18next'
import { useEscalationEmailButton } from '../escalationEmail/EscalationEmail.utils'
import { useExportButton } from '../export/ExportButton.utils'
import {
  SAuctionsDetailMenuWrapper,
  SMenuItem
} from './AuctionsDetailMenu.styled'

interface Props {
  carId: string
  opportunityId: string | undefined
  isInHistory?: boolean
}

export function AuctionsDetailMenu({
  carId,
  opportunityId,
  isInHistory
}: Props) {
  const { t } = useTranslation(['core'])

  const { isLoading: exportLoading, exportPdf } = useExportButton(carId)
  const {
    sendEscalationEmail,
    isLoading: escalationLoading
  } = useEscalationEmailButton(opportunityId)

  return (
    <SAuctionsDetailMenuWrapper isInHistory={isInHistory}>
      <Dropdown
        closeOnEscape
        placement="bottom-start"
        padding="none"
        fullWidth
        css={css`
          border-radius: ${size(2)};
        `}
        trigger={
          <Button
            variant="outline"
            css={{ padding: `0 16px`, height: size(11) }}
          >
            <div
              css={css`
                position: relative;
                margin-right: 8px;
                width: 100%;
              `}
            >
              {t('core:more_actions')}
            </div>

            <div
              css={css`
                display: inline-flex;
                justify-content: center;
              `}
            >
              <IconChevronDown />
            </div>
          </Button>
        }
      >
        <Flex
          gap={1}
          variant="column"
          css={css`
            padding: 8px 0;
          `}
        >
          {carId && (
            <SMenuItem onClick={exportPdf} disabled={exportLoading}>
              <TextBody>
                {exportLoading ? t('core:preparing_pdf') : t('core:export_pdf')}
              </TextBody>
            </SMenuItem>
          )}

          <SMenuItem onClick={sendEscalationEmail} disabled={escalationLoading}>
            <TextBody>{t('core:send_escalation_mail')}</TextBody>
          </SMenuItem>
        </Flex>
      </Dropdown>
    </SAuctionsDetailMenuWrapper>
  )
}
