import { css } from '@emotion/react'
import { useSearchAuctionsAgg } from 'api/driverama/auctions/searchAuctionsAgg'
import { useEmployeesSearchQuery } from 'api/driverama/employees/search'
import {
  usePartnerMapper,
  usePartnersList
} from 'api/driverama/partners/search'
import { Flex } from 'driverama-core/components/Flex'
import { InputNumberControlled } from 'driverama-core/components/inputNumber/InputNumberControlled'
import { Select } from 'driverama-core/components/select/Select'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { SpinnerCentered } from 'driverama-core/components/spinner/SpinnerCentered'
import { TextLink } from 'driverama-core/components/text/link/TextLink'
import {
  TextBody,
  TextBodyBold,
  TextSubhead
} from 'driverama-core/components/text/Text'
import { size } from 'driverama-core/styles/spacing'
import { formatNum } from 'driverama-core/utils/formatting'
import { useLocalisation } from 'driverama-core/utils/localisation'
import { isNotNil, isNumber, Maybe, nameOf } from 'driverama-core/utils/types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchAuctionBids } from '../../../../api/driverama/auctions/auctionsBids'
import { AuctionDetailOtherForm } from '../form/AuctionDetailOtherForm'
import {
  FormValues,
  getDefaultFormValues
} from '../form/AuctionDetailOtherForm.utils'
import { AuctionDetailOtherSellingPrice } from '../form/AuctionDetailOtherSellingPrice'
import { SGrid, SItem } from './AuctionsDetailOther.styled'
import { getSourceTranslation } from './AuctionsDetailOther.utils'

interface Props {
  auctionId?: string
  isInHistory?: boolean
}

export function AuctionsDetailOther({ auctionId, isInHistory }: Props) {
  const { t } = useTranslation(['auction', 'core'])
  const { activeLocale } = useLocalisation(t)
  const [disabledEdit, setDisabledEdit] = useState(true)

  const partnerMapper = usePartnerMapper()
  const partners = usePartnersList()

  const editableFieldsEnabled = isInHistory && !disabledEdit

  const partnersOptions =
    partners.data?.content?.map(partner => ({
      value: partner.erpId,
      label: partnerMapper(partner.erpId) ?? partner.name
    })) ?? []

  const auctionQuery = useSearchAuctionsAgg(
    {
      ids: [auctionId].filter(isNotNil),
      carIds: [],
      carMakeIds: [],
      states: [],
      types: [],
      excludedOpportunityStates: [],
      opportunityLossReasons: []
    },
    {
      enabled: !!auctionId
    },
    {
      aggregated: false
    }
  )

  const auctionDetail = auctionQuery.data?.content?.[0]

  const partnersList = usePartnersList()

  const winningBid = auctionDetail?.winningBid?.price

  const currentAuctionBids = useSearchAuctionBids(
    {
      auctionIds: [auctionDetail?.id].filter(isNotNil)
    },
    {
      enabled: !!auctionDetail?.id
    },
    {
      sort: ['createdAt', 'desc']
    }
  )

  const carGeniusSearchQuery = useEmployeesSearchQuery(
    {
      ids: [auctionDetail?.opportunity?.buyerId].filter(isNotNil)
    },
    {
      enabled: !!auctionDetail?.opportunity?.buyerId
    }
  )

  const queries = [
    auctionQuery,
    currentAuctionBids,
    carGeniusSearchQuery,
    partnersList
  ]
  const isLoading = queries.some(query => query.isLoading)

  const mostRecentAuctionBid = currentAuctionBids?.data?.content[0]

  const winningBidder = auctionDetail?.winningBid?.bidderId as Maybe<string>

  const customerWishedPrice = auctionDetail?.opportunity?.customerWishedPrice

  const bpm = auctionDetail?.opportunity?.bpm

  const source = auctionDetail?.opportunity?.source

  const adUrl = auctionDetail?.opportunity?.adUrl

  const offeredPriceRange = auctionDetail?.opportunity?.priceOffer

  const sellingPrice = mostRecentAuctionBid?.sellingPrice
  const sellingPriceCurrency = mostRecentAuctionBid?.sellingPriceCurrencyCode

  const carGenius = carGeniusSearchQuery.data?.content.find(
    buyer => buyer.id === auctionDetail?.opportunity?.buyerId
  )

  const auctionWinner =
    (auctionDetail?.state === 'SUCCESSFUL' ||
      auctionDetail?.state === 'SUCCESSFUL_WINNER_CHANGED') &&
    partnersList.data?.content.find(
      partner => partner.erpId === auctionDetail?.winningBid?.partnerErpId
    )

  const previousAuctionWinningBid =
    auctionDetail?.previousAuctionWinningBid?.price

  const hasData =
    isNumber(customerWishedPrice) ||
    isNumber(winningBid) ||
    isNumber(bpm?.reclaimAmountEur) ||
    !!winningBidder ||
    !!auctionWinner ||
    !!source ||
    !!adUrl ||
    !!offeredPriceRange

  if (!hasData) {
    return null
  }

  if (isLoading) {
    return <SpinnerCentered />
  }

  const refetchAuctionValues = async () => {
    await auctionQuery.refetch()
    await currentAuctionBids.refetch()
  }

  return (
    <AuctionDetailOtherForm
      refetchAuctionValues={refetchAuctionValues}
      auctionId={auctionId}
      opportunityState={auctionDetail?.opportunity?.state}
      defaultValues={getDefaultFormValues(auctionDetail, mostRecentAuctionBid)}
      disabledEdit={disabledEdit}
      setDisabledEdit={setDisabledEdit}
    >
      <section>
        <TextSubhead>{t('auction:detail_other')}</TextSubhead>
        <Spacer axis="vertical" size={4} />

        <SGrid>
          {source && (
            <SItem>
              <TextBody>{t('auction:detail_source')}</TextBody>
              <TextBodyBold>{getSourceTranslation(source, t)}</TextBodyBold>
            </SItem>
          )}

          {adUrl && (
            <SItem>
              <TextBody>{t('auction:detail_ad_url')}</TextBody>
              <Flex
                variant="row"
                display="inline"
                css={css`
                  flex: 0 80%;
                  height: 100%;
                  position: relative;
                `}
                justify="end"
              >
                <TextLink
                  css={css`
                    position: absolute;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 100%;
                  `}
                  href={adUrl}
                  openNewTab
                >
                  {adUrl}
                </TextLink>
              </Flex>
            </SItem>
          )}

          {offeredPriceRange && (
            <SItem>
              <TextBody>{t('auction:detail_offered_price_range')}</TextBody>
              <TextBodyBold>
                {t('auction:detail_offered_price_range_value', {
                  priceFrom: offeredPriceRange.fromEur,
                  priceTo: offeredPriceRange.toEur
                })}
              </TextBodyBold>
            </SItem>
          )}

          {isNumber(customerWishedPrice) && (
            <SItem>
              <TextBody>{t('auction:detail_customer_wished_price')}</TextBody>
              <TextBodyBold>
                {t('auction:price', { price: customerWishedPrice })}
              </TextBodyBold>
            </SItem>
          )}
          {isNumber(winningBid) && (
            <SItem>
              <TextBody>{t('auction:detail_final_bid')}</TextBody>

              {editableFieldsEnabled ? (
                <InputNumberControlled
                  css={css`
                    text-align: right;
                  `}
                  name={nameOf<FormValues>('winningBidPrice')}
                  isDisabled={disabledEdit}
                  currency="€"
                />
              ) : (
                <TextBodyBold>
                  {t('auction:price', { price: winningBid })}
                </TextBodyBold>
              )}
            </SItem>
          )}

          {isNumber(sellingPrice) && sellingPriceCurrency && (
            <SItem>
              <TextBody>{t('auction:detail_selling_price')}</TextBody>

              {editableFieldsEnabled ? (
                <AuctionDetailOtherSellingPrice
                  disabledEdit={!editableFieldsEnabled}
                />
              ) : (
                <TextBodyBold>
                  {t('auction:detail_selling_price_value', {
                    price: formatNum(
                      sellingPrice,
                      {
                        style: 'currency',
                        currency: sellingPriceCurrency,
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      },
                      activeLocale.value
                    )
                  })}
                </TextBodyBold>
              )}
            </SItem>
          )}
          {isNumber(previousAuctionWinningBid) && (
            <SItem>
              <TextBody>{t('auction:detail_previous_bid')}</TextBody>
              <TextBodyBold>
                {t('auction:price', { price: previousAuctionWinningBid })}
              </TextBodyBold>
            </SItem>
          )}
          {bpm &&
            isNumber(bpm.reclaimAmountEur) &&
            bpm.calculationState !== 'FAILED' && (
              <SItem>
                <TextBody>{t('auction:detail_reclaim_amount')}</TextBody>
                <TextBodyBold>
                  {t('auction:price', { price: bpm.reclaimAmountEur })}
                </TextBodyBold>
              </SItem>
            )}

          {carGenius && (
            <SItem>
              <TextBody>{t('auction:detail_car_genius')}</TextBody>
              <TextBodyBold>
                {[carGenius.firstName, carGenius.surname]
                  .filter(isNotNil)
                  .join(' ')}
              </TextBodyBold>
            </SItem>
          )}

          {auctionWinner && (
            <SItem>
              <TextBody>{t('auction:detail_winning_partner')}</TextBody>
              {editableFieldsEnabled ? (
                <Select
                  css={css`
                    width: ${size(65)}!important;
                  `}
                  name="partnerId"
                  options={partnersOptions}
                  disabled={disabledEdit}
                />
              ) : (
                <TextBodyBold title={auctionWinner.erpId}>
                  {partnerMapper(auctionWinner.erpId)}
                </TextBodyBold>
              )}
            </SItem>
          )}
          {isInHistory && !!winningBidder && (
            <SItem>
              <TextBody>{t('auction:detail_bidder')}</TextBody>
              <TextBodyBold>{winningBidder}</TextBodyBold>
            </SItem>
          )}
        </SGrid>
      </section>
    </AuctionDetailOtherForm>
  )
}
