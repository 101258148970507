import { URLS } from 'constants/api'
import { operations } from 'driverama-core/api/driverama/generated/opportunities'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { useQuery, UseQueryOptions } from 'react-query'
import { apiAuthFetcher } from 'utils/authFetcher'
import { QUERY_KEYS } from '../../../constants/queryKeys'

export type NoteSearchBody = operations['searchNotes']['requestBody']['content']['application/json']
export type NoteSearchResponse =
  | operations['searchNotes']['responses']['200']['content']['application/com.driverama-v1+json']
  | undefined

type QueryOpts = UseQueryOptions<unknown, unknown, NoteSearchResponse>

export function useNotesSearchQuery(body: NoteSearchBody, opts?: QueryOpts) {
  return useQuery(
    QUERY_KEYS.noteSearch(body),
    async () => {
      const res = await apiAuthFetcher<NoteSearchResponse>(
        URLS.opportunityNotesSearch,
        {
          method: HTTPMethod.POST,
          body
        }
      )

      return res.json
    },
    {
      refetchOnWindowFocus: false,
      ...opts
    }
  )
}
