import { paths } from 'driverama-core/api/driverama/generated/auctions'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { addSearchParams } from 'driverama-core/utils/searchParams'
import { useQuery, UseQueryOptions } from 'react-query'
import { apiAuthFetcher } from 'utils/authFetcher'
import { URLS } from '../../../constants/api'
import { QUERY_KEYS } from '../../../constants/queryKeys'

type AuctionSearchBody = paths['/auctions/search-agg']['post']['requestBody']['content']['application/json']
export type AuctionSearchResponseAgg = paths['/auctions/search-agg']['post']['responses']['200']['content']['application/com.driverama-v1+json']
export type AuctionSearchResponseAggItem = AuctionSearchResponseAgg['content'][number]

type QueryOpts = UseQueryOptions<unknown, unknown, AuctionSearchResponseAgg>

export function useSearchAuctionsAgg(
  body: AuctionSearchBody,
  opts?: QueryOpts,
  params?: paths['/auctions/search-agg']['post']['parameters']['query']
) {
  return useQuery(
    QUERY_KEYS.auctionsSearchAgg(body),
    async () => {
      const res = await apiAuthFetcher<AuctionSearchResponseAgg>(
        addSearchParams(URLS.auctionSearchAgg, params),
        {
          method: HTTPMethod.POST,
          body
        }
      )
      return res.json
    },
    {
      refetchOnWindowFocus: false,
      ...opts
    }
  )
}
