import { QueryResult, useCarFilesQuery } from 'api/driverama/cars/carFiles'
import { addSearchParams } from 'driverama-core/utils/searchParams'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { CarDetailMapped } from '../AuctionsDetail.utils'

export type MutationBody = {
  files: { url: string; name: string }[]
}

export function resolvePrintUrl(url: string) {
  // ignore resizing if feature flag is disabled
  // the idea is to reduce the usage of Vercel image optimization
  // not sure, why Vercel tracks usages of `sharp` in API routes, but whatever
  if (!driverama.flags.IS_PRINT_IMAGE_RESIZING_ENABLED) return url
  return addSearchParams('/api/resize', { url, w: 500, q: 80 })
}

export function useCarImages(
  carId: string,
  carMedia: CarDetailMapped['media'] | undefined,
  firstOnly?: boolean
) {
  return useCarFilesQuery(carId, carMedia, firstOnly, {
    enabled: !!carMedia?.length,
    staleTime: 3600
  })
}

export async function downloadImagesZip(
  data: QueryResult | undefined,
  carTitle: string | undefined,
  carYearOfMake: number | undefined
): Promise<void> {
  const folderName = `Auction - ${carTitle} - ${carYearOfMake}`
  const zip = new JSZip()
  const folder = zip.folder(folderName)

  const listOfUrls = data?.map(({ url }) => url)

  if (data && listOfUrls && folder) {
    await Promise.all(
      listOfUrls.map(async url => {
        const file = data.find(({ url: imageUrl }) => imageUrl === url)

        const res = await fetch('/api/auth/zip', {
          method: 'POST',
          body: JSON.stringify({ url, name: file?.type || '' })
        })

        if (file) {
          const name = `${file.type}-${file.id}.jpg`
          const imageBlob = await res.blob()

          const imgData = new File([imageBlob], name)

          folder.file(name, imgData)
        }
      })
    )

    const content = await zip.generateAsync({ type: 'blob' })

    saveAs(content, `${folderName}.zip`)
  }
}
