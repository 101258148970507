import { operations } from 'api/driverama/generated/lov'
import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { apiFetcher } from 'driverama-core/utils/fetcher'
import { useQuery, UseQueryOptions } from 'react-query'

export type LovCountriesResponse = operations['getCountries']['responses']['200']['content']['application/com.driverama-v1+json']
type QueryParams = operations['getCountries']['parameters']['query']

type QueryOpts = UseQueryOptions<unknown, unknown, LovCountriesResponse>

export function useLovCountriesQuery(params?: QueryParams, opts?: QueryOpts) {
  return useQuery(
    QUERY_KEYS.lovCountries(params),
    async () => {
      const res = await apiFetcher<LovCountriesResponse>(URLS.lovCountries, {
        searchParams: params
      })

      return res.json
    },
    { staleTime: Infinity, refetchOnWindowFocus: false, ...opts }
  )
}
