import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { paths } from 'driverama-core/api/driverama/generated/cars'
import { useQuery, UseQueryOptions } from 'react-query'
import { apiAuthFetcher } from 'utils/authFetcher'
import { AuctionSearchResponseAggItem } from '../auctions/searchAuctionsAgg'

export type CarDetailResponse = paths['/cars/auction-app/cars/{carId}']['get']['responses']['200']['content']['application/com.driverama-v1+json']

type AuctionState = AuctionSearchResponseAggItem['state']

type QueryOpts = UseQueryOptions<unknown, unknown, CarDetailResponse>

export function useCarDetailQuery(
  id?: string,
  auctionState?: AuctionState,
  opts?: QueryOpts
) {
  return useQuery(
    [QUERY_KEYS.carDetail(id), auctionState],
    async () => {
      const res = await apiAuthFetcher<CarDetailResponse>(URLS.carDetail(id))
      return res.json
    },
    opts
  )
}
