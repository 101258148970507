import { operations } from 'api/driverama/generated/lov'
import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { apiFetcher } from 'driverama-core/utils/fetcher'
import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'

export type LovColorsResponse = operations['getColors']['responses']['200']['content']['application/com.driverama-v1+json']
type QueryParams = operations['getColors']['parameters']['query']

type QueryOpts = UseQueryOptions<unknown, unknown, LovColorsResponse>

export function useLovColorsQuery(
  searchParams?: QueryParams,
  opts?: QueryOpts
) {
  return useQuery(
    QUERY_KEYS.lovColors(searchParams),
    async () => {
      const res = await apiFetcher<LovColorsResponse>(URLS.lovColors, {
        searchParams
      })

      return res.json
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      ...opts
    }
  )
}

export function useColorList(opts?: QueryOpts) {
  const { data, ...rest } = useLovColorsQuery({
    active: true,
    sort: ['order,asc'],
    ...opts
  })

  const colors = useMemo(() => {
    return (
      data?.content.map(color => ({
        label: color.name || color.id,
        value: color.id,
        svgIcon: color.svgIcon
      })) || []
    )
  }, [data])

  return { ...rest, colors }
}
