import { operations } from 'api/driverama/generated/lov'
import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { apiFetcher } from 'driverama-core/utils/fetcher'
import { useQuery, UseQueryOptions } from 'react-query'

export type LovTechnicalItemsResponse = operations['getItems']['responses']['200']['content']['application/com.driverama-v1+json']
type LovTechnicalItemsParams = operations['getItems']['parameters']['query']
type LovTechnicalItemsBody = operations['getItems']['requestBody']['content']['application/json']
type LovTechnicalItemsOpts = UseQueryOptions<
  unknown,
  unknown,
  LovTechnicalItemsResponse
>

export function useLovTechnicalItemsQuery(
  body?: LovTechnicalItemsBody,
  searchParams?: LovTechnicalItemsParams,
  opts?: LovTechnicalItemsOpts
) {
  return useQuery(
    QUERY_KEYS.lovTechnicalItems(searchParams, body),
    async () => {
      const res = await apiFetcher<LovTechnicalItemsResponse>(
        URLS.lovTechnicalItems,
        { method: HTTPMethod.POST, body, searchParams }
      )
      return res.json
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      ...opts
    }
  )
}
