import { css } from '@emotion/react'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { isNotNil } from 'driverama-core/utils/types'
import { useTranslation } from 'react-i18next'
import { AuctionsDetailCondition } from 'sections/auctions/detail/condition/AuctionsDetailCondition'
import { AuctionsDetailError } from 'sections/auctions/detail/error/AuctionsDetailError'
import { AuctionsDetailFeatures } from 'sections/auctions/detail/features/AuctionsDetailFeatures'
import { AuctionsDetailGallery } from 'sections/auctions/detail/gallery/AuctionsDetailGallery'
import { AuctionsDetailIdentification } from 'sections/auctions/detail/identification/AuctionsDetailIdentification'
import { AuctionsDetailLocation } from 'sections/auctions/detail/location/AuctionsDetailLocation'
import { AuctionsDetailParameters } from 'sections/auctions/detail/parameters/AuctionsDetailParameters'
import { AuctionsDetailRepairCosts } from 'sections/auctions/detail/repairCosts/AuctionsDetailRepairCosts'
import { AuctionsDetailTechnical } from 'sections/auctions/detail/technical/AuctionsDetailTechnical'
import { useSearchAuctionsAgg } from '../../../api/driverama/auctions/searchAuctionsAgg'
import { AuctionListMode } from '../Auctions.utils'
import { AuctionsDetailAccidents } from './accidents/AuctionsDetailAccidents'
import { SContainer, SSpinner } from './AuctionsDetail.styled'
import {
  AuctionState,
  useCarDetail,
  useLogAuctionDetail,
  useRelatedAuctions
} from './AuctionsDetail.utils'
import { AuctionDetailBonusesMaluses } from './bonusesMaluses/AuctionDetailBonusesMaluses'
import {
  AuctionDiffContext,
  extractDiffs,
  useAuctionDiffs
} from './diff/Diff.utils'
import { parameterKeys } from './diff/parameters/Parameters.utils'
import { AuctionsDetailOther } from './other/AuctionsDetailOther'
import { AuctionsDetailRelated } from './related/AuctionDetailRelated'

interface Props {
  carId: string | undefined
  isInHistory?: boolean
  auctionId?: string
  mode?: AuctionListMode
  plannedEndAt?: string | null
  auctionState?: AuctionState
}

export function AuctionsDetail({
  carId,
  isInHistory,
  auctionId,
  mode,
  plannedEndAt,
  auctionState
}: Props) {
  const carQuery = useCarDetail(carId, auctionState)
  const { t } = useTranslation('auction')
  const diffQuery = useAuctionDiffs(carQuery.data, auctionId, isInHistory)

  const auctionQuery = useSearchAuctionsAgg(
    {
      ids: [auctionId].filter(isNotNil),
      carIds: [],
      carMakeIds: [],
      states: [],
      types: [],
      excludedOpportunityStates: [],
      opportunityLossReasons: []
    },
    {
      enabled: !!auctionId
    },
    {
      aggregated: false
    }
  )

  const auctionDetail = auctionQuery?.data?.content?.[0]

  const {
    relatedAuctionsQuery,
    previouslyAuctioned,
    restartedAuctions
  } = useRelatedAuctions(auctionDetail)

  const queries = [carQuery, auctionQuery, relatedAuctionsQuery]
  const isLoading = queries.some(q => q.isLoading)
  const isError = queries.some(q => q.isError)

  useLogAuctionDetail({
    auctionId,
    isLoading: isLoading,
    mode,
    plannedEndAt,
    model: carQuery.data?.car.model,
    make: carQuery.data?.car.make,
    yearOfMake: carQuery.data?.car.yearOfMake
  })

  return isLoading ? (
    <SSpinner />
  ) : isError || !carQuery.data ? (
    <AuctionsDetailError />
  ) : (
    <AuctionDiffContext.Provider value={{ diffs: diffQuery.data ?? [] }}>
      <SContainer>
        <AuctionsDetailGallery
          carId={carQuery.data.car.id}
          carMedia={carQuery.data.media}
          carYearOfMake={carQuery.data.car.yearOfMake}
          carTitle={carQuery.data.car.title}
          auctionId={auctionId}
        />

        <Spacer
          axis="vertical"
          size={[4, 4, 10]}
          css={css`
            @media print {
              display: none;
            }
          `}
        />

        <AuctionsDetailIdentification
          license={carQuery.data.car.licensePlate}
          vin={carQuery.data.car.vin}
          diffs={extractDiffs(
            diffQuery.data ?? [],
            '$.car.vin',
            '$.car.licensePlate'
          )}
        />

        <Spacer axis="vertical" size={[4, 4, 10]} />

        {!!restartedAuctions?.length && (
          <>
            <AuctionsDetailRelated
              title={t('detail_restarted_auctions')}
              tooltip={t('detail_restarted_auctions_tooltip')}
              auctions={restartedAuctions}
            />
            <Spacer axis="vertical" size={[4, 4, 10]} />
          </>
        )}

        {!!previouslyAuctioned?.length && (
          <>
            <AuctionsDetailRelated
              title={t('detail_previously_auctioned')}
              tooltip={t('detail_previously_auctioned_tooltip')}
              auctions={previouslyAuctioned}
            />
            <Spacer axis="vertical" size={[4, 4, 10]} />
          </>
        )}

        <AuctionsDetailParameters
          auctionId={auctionId}
          mappedData={carQuery.data}
          diffs={extractDiffs(diffQuery?.data ?? [], ...parameterKeys)}
        />

        <Spacer axis="vertical" size={[4, 4, 10]} />

        <AuctionsDetailAccidents
          mappedData={carQuery.data}
          diffs={extractDiffs(
            diffQuery.data ?? [],
            '$.accident.front',
            '$.accident.rear',
            '$.accident.left',
            '$.accident.right',
            '$.accident.roof'
          )}
        />

        <Spacer axis="vertical" size={[4, 4, 10]} />

        <AuctionsDetailLocation
          appointment={carQuery.data?.car.auction?.opportunity?.appointment}
        />

        <Spacer axis="vertical" size={10} />

        <AuctionsDetailRepairCosts
          car={carQuery.data}
          diffs={diffQuery.data?.filter(
            diff =>
              diff.propertyName.match(/carCosts/) ||
              diff.propertyName.match(
                /exteriorTechnicalConditionCategory.sums.totalRepairCosts/
              ) ||
              diff.propertyName.match(
                /interiorTechnicalConditionCategory.sums.totalRepairCosts/
              ) ||
              diff.propertyName.match(
                /mechanicsTechnicalConditionCategory.sums.totalRepairCosts/
              )
          )}
        />

        <Spacer axis="vertical" size={[4, 4, 10]} />

        <AuctionsDetailOther auctionId={auctionId} isInHistory={isInHistory} />

        <Spacer axis="vertical" size={[4, 4, 10]} />

        <AuctionsDetailFeatures
          features={carQuery.data.car.featuresMapped || []}
          carId={carQuery.data.car?.id}
          auctionId={auctionId}
          diffs={extractDiffs(diffQuery.data ?? [], '$.car.features')}
        />

        <Spacer axis="vertical" size={[4, 4, 10]} />

        <AuctionDetailBonusesMaluses
          car={carQuery.data}
          diffs={extractDiffs(diffQuery.data ?? [], '$.bonusesMaluses')}
        />

        <Spacer axis="vertical" size={[4, 4, 10]} />

        <AuctionsDetailCondition
          carId={carQuery.data.car.id}
          carTechnicalSections={carQuery.data.technicalConditionSections}
          diffs={diffQuery.data?.filter(diff =>
            diff.propertyName.match(
              /technicalConditionSection.sections(.*?).rating/
            )
          )}
        />

        <Spacer axis="vertical" size={[4, 4, 10]} />

        <AuctionsDetailTechnical
          carTechnicalCategories={carQuery.data.technicalConditionCategories}
          carTechnicalItems={carQuery.data.technicalConditionItems}
          carTechnicalSections={carQuery.data.technicalConditionSections}
          auctionId={auctionId}
        />

        <Spacer axis="vertical" size={[12, 12, 10]} />
      </SContainer>
    </AuctionDiffContext.Provider>
  )
}
