import { operations } from 'api/driverama/generated/lov'
import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { apiFetcher } from 'driverama-core/utils/fetcher'
import { Maybe } from 'driverama-core/utils/types'
import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'

export type LovModelLinesSearchResponse = operations['getModelLines']['responses']['200']['content']['application/com.driverama-v1+json']
type QueryParams = operations['getModelLines']['parameters']['query']
type QueryBody = operations['getModelLines']['requestBody']['content']['application/json']

type QueryOpts = UseQueryOptions<unknown, unknown, LovModelLinesSearchResponse>

export function useLovModelLinesSearchQuery(
  body: QueryBody,
  searchParams?: QueryParams,
  opts?: QueryOpts
) {
  return useQuery(
    QUERY_KEYS.lovModelLinesSearch(body),
    async () => {
      const res = await apiFetcher<LovModelLinesSearchResponse>(
        URLS.lovModelLinesSearch,
        { method: HTTPMethod.POST, body, searchParams }
      )

      return res.json
    },
    { staleTime: Infinity, refetchOnWindowFocus: false, ...opts }
  )
}

type ModelLineListArgs = {
  makeId?: Maybe<string>
  modelId?: Maybe<string>
  year?: Maybe<number>
}

export function useModelLineList(args: ModelLineListArgs) {
  const { makeId, modelId, year } = args

  const { data, ...rest } = useLovModelLinesSearchQuery(
    {
      filter: {
        ids: [],
        makeIds: makeId ? [makeId] : [],
        modelIds: modelId ? [modelId] : [],
        yearFromTo: year ?? undefined,
        yearFromIncludeNull: true,
        yearToFrom: year ?? undefined,
        yearToIncludeNull: true,
        // @ts-expect-error not defined in openapi
        active: true
      }
    },
    {},
    { enabled: !!makeId && !!modelId }
  )

  const modelLines = useMemo(() => {
    return (
      data?.content?.map(modeLine => ({
        label: modeLine.name || modeLine.id,
        value: modeLine.id
      })) || []
    )
  }, [data])

  return { ...rest, modelLines }
}
