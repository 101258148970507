import styled from '@emotion/styled'
import { Tooltip } from 'driverama-core/components/tooltip/Tooltip'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color } from 'driverama-core/styles/variables'

export const SGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${size(1)};

  @media ${media.gt('tablet')}, print {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${size(10)};
  }
`

export const SItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: ${size(1)};

  padding: ${size(4)} 0;

  border-bottom: 2px solid ${color('night-l-650')};
`

export const SToolTip = styled(Tooltip)`
  height: ${size(6)};
  display: flex;
  align-items: center;
  padding-right: ${size(2)};
  margin-right: -${size(2)};
`
