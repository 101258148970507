import { useAuctionSearchByCarId } from 'api/driverama/auctions/searchAuctions'
import { createContext } from 'react'
import {
  AuctionDiff,
  TypedAuctionDiff,
  useAuctionDiff
} from '../../../../api/driverama/auctions/diff'
import { CarDetailMapped } from '../AuctionsDetail.utils'
import { useExtraSetOfTiresDiff } from './wheels/Wheels.utils'

export function extractDiffs<T extends string>(
  diffs: AuctionDiff[],
  ...args: T[]
) {
  return diffs.filter(diff =>
    args.includes(diff.propertyName as T)
  ) as TypedAuctionDiff<T>[]
}

export function useAuctionDiffs(
  carObject?: CarDetailMapped,
  auctionId?: string,
  isInHistory?: boolean
) {
  const auction = useAuctionSearchByCarId(carObject?.car?.id, isInHistory)

  const sortedAuctionsByCreatedAt = auction.data?.content.sort((a, b) => {
    const aCreated = new Date(a.createdAt).getTime()
    const bCreated = new Date(b.createdAt).getTime()
    return bCreated - aCreated
  })

  const currentAuctionIndex = sortedAuctionsByCreatedAt?.findIndex(
    sortedAuction => sortedAuction.id === auctionId
  )
  const previousAuction =
    typeof currentAuctionIndex === 'number' && currentAuctionIndex !== -1
      ? sortedAuctionsByCreatedAt?.[currentAuctionIndex + 1]
      : null

  const diffQuery = useAuctionDiff(auctionId, previousAuction?.id, isInHistory)

  const extraSetOfTiresDiff = useExtraSetOfTiresDiff(diffQuery.data ?? [])

  return {
    ...diffQuery,
    data: [
      ...(diffQuery.data ?? []),
      ...(extraSetOfTiresDiff ? [extraSetOfTiresDiff] : [])
    ]
  }
}

type AuctionDiffContextType = {
  diffs: AuctionDiff[]
}

export const AuctionDiffContext = createContext<AuctionDiffContextType>({
  diffs: []
})
