import { operations } from 'api/driverama/generated/lov'
import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { apiFetcher } from 'driverama-core/utils/fetcher'
import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'

export type LovDrivesResponse = operations['getCarDrives']['responses']['200']['content']['application/com.driverama-v1+json']
type QueryParams = operations['getCarDrives']['parameters']['query']

type QueryOpts = UseQueryOptions<unknown, unknown, LovDrivesResponse>

export function useLovDrivesQuery(params?: QueryParams, opts?: QueryOpts) {
  return useQuery(
    QUERY_KEYS.lovDrives(params),
    async () => {
      const res = await apiFetcher<LovDrivesResponse>(URLS.lovDrives, {
        searchParams: params
      })

      return res.json
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      ...opts
    }
  )
}

export function useDriveList() {
  const { data, ...rest } = useLovDrivesQuery({ active: true })

  const drives = useMemo(() => {
    return (
      data?.map(drive => ({
        label: drive.name || drive.id,
        value: drive.id
      })) || []
    )
  }, [data])

  return { drives, ...rest }
}
