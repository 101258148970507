import { TypedAuctionDiff } from 'api/driverama/auctions/diff'
import { isNotNil, isNum } from 'driverama-core/utils/types'
import { TFunction } from 'react-i18next'
import { CarDetailMapped } from 'sections/auctions/detail/AuctionsDetail.utils'
import {
  ParameterKey,
  getFieldDiff,
  getMakeAndModelDiff,
  getServiceBookTypeDiff,
  towbarState
} from '../diff/parameters/Parameters.utils'

const hasExtraSetOfTires = (wheels: CarDetailMapped['wheels']) => {
  return !!wheels?.filter(
    wheel =>
      wheel.position === 'EXTRA_FRONT_LEFT' ||
      wheel.position === 'EXTRA_FRONT_RIGHT' ||
      wheel.position === 'EXTRA_REAR_LEFT' ||
      wheel.position === 'EXTRA_REAR_RIGHT'
  ).length
}

type ParameterColumnsResponse = {
  label: string
  value: number | string | undefined
  warning?: string
  warningTriggered?: boolean
  diff?: TypedAuctionDiff<ParameterKey>
}[]

export const parameterColumns = (
  t: TFunction<('auction' | 'core')[]>,
  data: CarDetailMapped,
  diffs?: TypedAuctionDiff<ParameterKey>[]
): ParameterColumnsResponse => {
  const currentRegion = process.env.NEXT_PUBLIC_REGION?.toUpperCase()

  return [
    {
      label: t('auction:detail_parameters_make_and_model'),
      value: `${data.car?.make} ${data.car?.model}`,
      diff: getMakeAndModelDiff(diffs, data.car?.make, data.car?.model)
    },
    {
      label: t('auction:detail_parameters_year_registration'),
      value: data.car?.firstRegistrationDate
        ? t('auction:detail_parameters_year_registration_value', {
            date: new Date(data.car?.firstRegistrationDate)
          })
        : undefined,
      diff: getFieldDiff('$.car.firstRegistrationDate', diffs)
    },
    {
      label: t('auction:detail_parameters_model_line'),
      value: data.car?.modelLine,
      diff: getFieldDiff('$.car.modelLineId', diffs)
    },
    {
      label: t('auction:detail_parameters_year_manufacture'),
      value: data.car?.yearOfMake,
      diff: getFieldDiff('$.car.yearOfMake', diffs)
    },
    {
      label: t('auction:detail_parameters_engine_name'),
      value: [
        data.car?.engine?.name,
        t('auction:detail_parameters_engine_power_value', {
          value: data.car?.engine?.powerKw
        })
      ].join(' '),
      diff: getFieldDiff('$.car.engineId', diffs)
    },
    {
      label: t('auction:detail_parameters_transmission'),
      value: data.car?.transmission,
      diff: getFieldDiff('$.car.transmissionId', diffs)
    },
    {
      label: t('auction:detail_parameters_drive'),
      value: data.car?.drive,
      diff: getFieldDiff('$.car.driveId', diffs)
    },
    {
      label: t('auction:detail_parameters_mileage'),
      value: t('auction:detail_parameters_mileage_value', {
        value: data.car?.speedometerMileageKm
      }),
      diff: getFieldDiff('$.car.speedometerMileageKm', diffs)
    },
    {
      label: t('auction:detail_parameters_fuel'),
      value: data.car?.fuelType,
      diff: getFieldDiff('$.car.fuelTypeId', diffs)
    },
    {
      label: t('auction:detail_parameters_owners'),
      value: data.car?.ownersCount,
      diff: getFieldDiff('$.car.ownersCount', diffs)
    },
    {
      label: t('auction:detail_parameters_service_book'),
      value: t(
        `core:${(
          data.car?.serviceBook?.type !== 'NONE'
        ).toString()}` as `core:${'true' | 'false'}`
      ),
      diff: getServiceBookTypeDiff(t, diffs, data.car?.serviceBook?.type)
    },
    {
      label: t('auction:detail_parameters_country_manufacture'),
      value: data.car?.originCountryId,
      diff: getFieldDiff('$.car.originCountryId', diffs),
      warningTriggered: currentRegion !== data.car?.originCountryId,
      warning: t('auction:unmatched_locations_tooltip')
    },
    {
      label: t('auction:detail_parameters_service_book_date'),
      value: data.car?.serviceBook?.lastServicingDate
        ? t('auction:detail_parameters_service_book_date_value', {
            date: new Date(data.car?.serviceBook.lastServicingDate)
          })
        : '-',
      diff: getFieldDiff('$.car.serviceBook.lastServicingDate', diffs)
    },
    {
      label: t('auction:detail_parameters_service_book_last_raid'),
      value: data.car?.serviceBook?.lastMileage
        ? t('auction:detail_parameters_mileage_value', {
            value: data.car?.serviceBook.lastMileage
          })
        : '-',
      diff: getFieldDiff('$.car.serviceBook.lastMileage', diffs)
    },
    {
      label: t('auction:detail_parameters_body_type'),
      value: data.car?.bodyType,
      diff: getFieldDiff('$.car.bodyId', diffs)
    },
    {
      label: t('auction:detail_parameters_doors'),
      value: data.car?.doorsCount,
      diff: getFieldDiff('$.car.doorsCount', diffs)
    },
    {
      label: t('auction:detail_parameters_generation'),
      value: data.car?.extendedModel,
      diff: getFieldDiff('$.car.extendedModelId', diffs)
    },
    {
      label: t('auction:detail_parameters_seats'),
      value: data.car?.seatsCount,
      diff: getFieldDiff('$.car.seatsCount', diffs)
    },
    {
      label: t('auction:detail_parameters_technical_inspection'),
      value: !data.car?.technicalInspectionValidityUntilDate
        ? t('core:false')
        : t('auction:detail_parameters_technical_inspection_value', {
            date: new Date(data.car?.technicalInspectionValidityUntilDate)
          }),
      diff: getFieldDiff('$.car.technicalInspectionValidityUntilDate', diffs)
    },
    {
      label: t('auction:detail_parameters_vat_deduction'),
      value: t(
        `core:${(!!data.car?.vatDeductible).toString()}` as `core:${
          | 'true'
          | 'false'}`
      ),
      diff: getFieldDiff('$.car.vatDeductible', diffs)
    },
    {
      label: t('auction:detail_parameters_exterior_color'),
      value: data.car?.color,
      diff: getFieldDiff('$.car.colorId', diffs)
    },
    {
      label: t('auction:detail_parameters_metallic'),
      value: t(
        `core:${data.car?.metallicColor?.toString()}` as `core:${
          | 'true'
          | 'false'}`
      ),
      diff: getFieldDiff('$.car.metallicColor', diffs)
    },
    {
      label: t('auction:detail_parameters_factory_warranty_until'),
      value: data.car?.factoryWarrantyUntilDate
        ? t('auction:detail_parameters_factory_warranty_up_to_value', {
            date: new Date(data.car?.factoryWarrantyUntilDate)
          })
        : t('core:not_yet_detected'),
      diff: getFieldDiff('$.car.factoryWarrantyUntilDate', diffs)
    },
    {
      label: t('auction:detail_parameters_factory_warranty_up_to'),
      value: isNum(data.car?.factoryWarrantyUntilMileageKm)
        ? t('core:km_format', { km: data.car?.factoryWarrantyUntilMileageKm })
        : t('core:not_yet_detected'),
      diff: getFieldDiff('$.car.factoryWarrantyUntilMileageKm', diffs)
    },
    {
      label: t('auction:detail_parameters_extra_tires'),
      value: hasExtraSetOfTires(data.wheels) ? t('core:yes') : t('core:no'),
      diff: getFieldDiff('$.wheels.extraSetOfTires', diffs)
    },
    {
      label: t('auction:detail_parameters_towbar'),
      value: data.car?.towbar ? towbarState(data.car?.towbar, t) : t('core:no'),
      diff: getFieldDiff('$.car.towbar', diffs)
    },
    {
      label: t('auction:detail_parameters_co2_title'),
      value: t('auction:detail_parameters_co2_value', {
        value: data.car?.co2EmissionsGramsPerKm ?? '-'
      }),
      diff: getFieldDiff('$.car.co2EmissionsGramsPerKm', diffs)
    },
    {
      label: t('auction:detail_parameters_emission_norm'),
      value: data.car?.emissionNormName,
      diff: getFieldDiff('$.car.emissionNormId', diffs)
    }
  ].filter(isNotNil)
}
