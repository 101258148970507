import { operations } from 'api/driverama/generated/lov'
import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { apiFetcher } from 'driverama-core/utils/fetcher'
import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'

export type LovTransmissionsResponse = operations['getTransmissions']['responses']['200']['content']['application/com.driverama-v1+json']
type QueryParams = operations['getTransmissions']['parameters']['query']

type QueryOpts = UseQueryOptions<unknown, unknown, LovTransmissionsResponse>

export function useLovTransmissionsQuery(
  searchParams?: QueryParams,
  opts?: QueryOpts
) {
  return useQuery(
    QUERY_KEYS.lovTransmissions(searchParams),
    async () => {
      const res = await apiFetcher<LovTransmissionsResponse>(
        URLS.lovTransmissions,
        { searchParams }
      )

      return res.json
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      ...opts
    }
  )
}

export function useTransmissionList() {
  const { data, ...rest } = useLovTransmissionsQuery({ active: true })

  const transmissions = useMemo(() => {
    return (
      data?.content.map(transmission => ({
        label: transmission.name || transmission.id,
        value: transmission.id
      })) || []
    )
  }, [data])

  return { transmissions, ...rest }
}
