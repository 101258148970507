import { AuctionDiff } from 'api/driverama/auctions/diff'
import { useLovTechnicalCategoriesQuery } from 'api/driverama/lov/lovTechnicalCategories'
import { useContext, useMemo } from 'react'
import { CarDetailMapped } from '../../AuctionsDetail.utils'
import { AuctionDiffContext } from '../../diff/Diff.utils'

type MappedCategory = CarDetailMapped['technicalConditionCategories']['categories'][number] & {
  name?: string
  problematicItemsCountDiff?: AuctionDiff
}

export function useCategories(
  categories: CarDetailMapped['technicalConditionCategories'],
  sectionId: string
) {
  const { diffs } = useContext(AuctionDiffContext)

  const {
    data: lovCategoriesData,
    isLoading,
    isError
  } = useLovTechnicalCategoriesQuery(
    { filter: { ids: [], sectionIds: [sectionId] } },
    undefined,
    { refetchOnWindowFocus: false }
  )

  const data = useMemo(() => {
    if (!!categories && lovCategoriesData) {
      return (lovCategoriesData.content ?? []).reduce((acc, curr) => {
        const categoryObj = categories.categories?.find(
          category => category.categoryId === curr.id
        )

        if (categoryObj) {
          return [
            ...acc,
            {
              ...categoryObj,
              name: curr.name,
              hasItemPhotosDiff: diffs.find(diff =>
                diff.propertyName.includes(
                  `categories.[${categoryObj.id}].hasItemPhotos`
                )
              ),
              problematicItemsCountDiff: diffs.find(diff =>
                diff.propertyName.includes(
                  `categories.[${categoryObj.id}].problematicItemsCount`
                )
              )
            }
          ]
        }

        return acc
      }, [] as MappedCategory[])
    }

    return undefined
  }, [categories, lovCategoriesData, diffs])

  return {
    data,
    isLoading,
    isError
  }
}
