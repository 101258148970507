import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { paths } from 'driverama-core/api/driverama/generated/auctions'
import { useQuery, UseQueryOptions } from 'react-query'
import { apiAuthFetcher } from 'utils/authFetcher'

export type AuctionDetailResponse =
  | paths['/admin/auctions/{auctionId}']['get']['responses']['200']['content']['application/com.driverama-v1+json']
  | undefined

type QueryOpts = UseQueryOptions<unknown, unknown, AuctionDetailResponse>

export function useAuctionDetailQuery(id?: string, opts?: QueryOpts) {
  return useQuery(
    QUERY_KEYS.auctionDetail(id),
    async () => {
      const res = await apiAuthFetcher<AuctionDetailResponse>(
        URLS.auctionDetail(id)
      )
      return res.json
    },
    {
      refetchOnWindowFocus: false,
      ...opts
    }
  )
}
