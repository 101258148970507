import { AuctionDiff, TypedAuctionDiff } from 'api/driverama/auctions/diff'
import { useLovBodiesQuery } from 'api/driverama/lov/lovBodies'
import { useLovColorsQuery } from 'api/driverama/lov/lovColors'
import { useLovDrivesQuery } from 'api/driverama/lov/lovDrives'
import { useLovEnginesSearchQuery } from 'api/driverama/lov/lovEnginesSearch'
import { useLovExtendedModelsSearchQuery } from 'api/driverama/lov/lovExtendedModelsSearch'
import { useLovFuelTypesQuery } from 'api/driverama/lov/lovFuelTypes'
import { useLovMakesQuery } from 'api/driverama/lov/lovMakes'
import { useLovModelLinesSearchQuery } from 'api/driverama/lov/lovModelLinesSearch'
import { useLovModelsSearchQuery } from 'api/driverama/lov/lovModelsSearch'
import { useLovTransmissionsQuery } from 'api/driverama/lov/lovTransmissions'
import { useEmissionNormList } from 'driverama-core/api/driverama/lov/lovEmissionNorms'
import { useMemo } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { CarDetailMapped } from '../../AuctionsDetail.utils'

export const parameterKeys = [
  '$.car.makeId',
  '$.car.modelId',
  '$.car.extendedModelId',
  '$.car.modelLineId',
  '$.car.bodyId',
  '$.car.doorsCount',
  '$.car.seatsCount',
  '$.car.fuelTypeId',
  '$.car.engineId',
  '$.car.transmissionId',
  '$.car.gearsCount',
  '$.car.driveId',
  '$.car.metallicColor',
  '$.car.colorId',
  '$.car.originCountryId',
  '$.car.ownersCount',
  '$.car.yearOfMake',
  '$.car.firstRegistrationDate',
  '$.car.speedometerMileageKm',
  '$.car.factoryWarrantyUntilDate',
  '$.car.factoryWarrantyUntilMileageKm',
  '$.car.technicalInspectionValidityUntilDate',
  '$.car.vatDeductible',
  '$.car.keysCount',
  '$.car.otherKeysCount',
  '$.car.reserveWheel',
  '$.car.repairKit',
  '$.car.emissionNormId',
  '$.car.towbar',
  '$.car.serviceBook.type',
  '$.car.serviceBook.lastServicingDate',
  '$.car.serviceBook.lastMileage',
  '$.car.co2EmissionsGramsPerKm',
  '$.car.newWhenLastAuctioned',
  '$.wheels.extraSetOfTires'
] as const

export type ParameterKey = typeof parameterKeys[number]

export type ParameterItemWithDiff = {
  label: string
  value: number | string | undefined
  diff?: TypedAuctionDiff<ParameterKey>
  warning?: string
  warningTriggered?: boolean
}

type ServiceBookType = NonNullable<
  CarDetailMapped['car']['serviceBook']
>['type']

type LOVDomain = {
  id: string
}

export function getFieldDiff(
  field: ParameterKey,
  diffs?: TypedAuctionDiff<ParameterKey>[]
) {
  return diffs?.find(diff => diff.propertyName === field)
}

function getMappedLOVValue<T extends LOVDomain>(
  lovValues: T[],
  item: AuctionDiff
) {
  return lovValues.find(lovValue => lovValue.id === item.oldValue)
}

export const towbarState = (
  towbar: CarDetailMapped['car']['towbar'],
  t: TFunction<('auction' | 'core')[]>
) => {
  switch (towbar) {
    case 'NONE':
      return t('auction:detail_parameters_towbar_none')

    case 'ELECTRIC':
      return t('auction:detail_parameters_towbar_electric')

    case 'MANUAL':
      return t('auction:detail_parameters_towbar_manual')

    default:
      return towbar
  }
}

export function getMakeAndModelDiff(
  diffs?: TypedAuctionDiff<ParameterKey>[],
  make?: string,
  model?: string
): TypedAuctionDiff<ParameterKey> | undefined {
  const makeDiff = diffs?.find(diff => diff.propertyName === '$.car.makeId')
  const modelDiff = diffs?.find(diff => diff.propertyName === '$.car.modelId')

  const makeModelDiffs = [makeDiff, modelDiff]

  const isAdded = makeModelDiffs.every(
    diff => diff?.changeType === 'VALUE_ADDED'
  )

  if (isAdded) {
    return {
      propertyName: '$.car.modelId',
      changeType: 'VALUE_ADDED',
      newValue: [make, model].join(' ')
    }
  }

  const isRemoved = makeModelDiffs.every(
    diff => diff?.changeType === 'VALUE_REMOVED'
  )

  if (isRemoved) {
    return {
      propertyName: '$.car.modelId',
      changeType: 'VALUE_REMOVED',
      oldValue: [makeDiff?.oldValue, modelDiff?.oldValue].join(' ')
    }
  }

  const hasMakeAndModelChanged =
    !!makeDiff?.changeType || !!modelDiff?.changeType

  if (!hasMakeAndModelChanged) {
    return
  }

  return {
    propertyName: '$.car.modelId',
    changeType: 'VALUE_CHANGED',
    oldValue: [makeDiff?.oldValue ?? make, modelDiff?.oldValue ?? model].join(
      ' '
    )
  }
}

function hasServiceBook(serviceBookType?: ServiceBookType) {
  return !!serviceBookType && ['PAPER', 'ELECTRONIC'].includes(serviceBookType)
}

export function getServiceBookTypeDiff(
  t: TFunction<('auction' | 'core')[]>,
  diffs?: TypedAuctionDiff<ParameterKey>[],
  serviceBook?: ServiceBookType
): TypedAuctionDiff<ParameterKey> | undefined {
  const serviceBookTypeDiff = diffs?.find(
    diff => diff.propertyName === '$.car.serviceBook.type'
  )

  if (serviceBookTypeDiff && serviceBookTypeDiff.oldValue !== serviceBook) {
    const diffValue = hasServiceBook(
      serviceBookTypeDiff?.oldValue as ServiceBookType
    )
    const actualValue = hasServiceBook(serviceBook)

    if (diffValue !== actualValue) {
      return {
        ...serviceBookTypeDiff,
        oldValue: t(
          `core:${(
            serviceBookTypeDiff.oldValue !== 'NONE'
          ).toString()}` as `core:${'true' | 'false'}`
        )
      }
    }
  }

  return
}

export function useParametersDiffs(
  diffs: TypedAuctionDiff<ParameterKey>[],
  car: CarDetailMapped,
  auctionId?: string
) {
  const { t } = useTranslation(['core', 'auction'])

  // LOV stuff
  const defaultOpts = {
    enabled: !!auctionId && !!car,
    refetchOnWindowFocus: false
  }

  function getChangePropertyId(
    propertyName: TypedAuctionDiff<ParameterKey>['propertyName']
  ) {
    const foundDiff = diffs.find(diff => diff.propertyName === propertyName)

    return foundDiff?.changeType === 'VALUE_ADDED'
      ? foundDiff.newValue
      : foundDiff?.oldValue
  }

  // if model changes, but change does not, we need to provide changeMakeId in order to fetch models correctly
  const changedMakeId = getChangePropertyId('$.car.makeId') ?? car.car?.makeId
  const changedModelId = getChangePropertyId('$.car.modelId')
  const changedModelLineId = getChangePropertyId('$.car.modelLineId')
  const changedExtendedModelId = getChangePropertyId('$.car.extendedModelId')
  const changedEngineId = getChangePropertyId('$.car.engineId')
  const changedColorId = getChangePropertyId('$.car.colorId')
  const changedTransmissionId = getChangePropertyId('$.car.transmissionId')

  const makes = useLovMakesQuery(undefined, defaultOpts)
  const models = useLovModelsSearchQuery(
    {
      filter: {
        ids: [changedModelId ?? ''],
        makeIds: [changedMakeId ?? ''],
        yearFromIncludeNull: true
      }
    },
    {
      ...defaultOpts,
      enabled: defaultOpts.enabled && !!changedModelId
    }
  )

  const bodies = useLovBodiesQuery(undefined, defaultOpts)
  const modelLines = useLovModelLinesSearchQuery(
    {
      filter: {
        ids: [changedModelLineId ?? ''],
        yearFromIncludeNull: true
      }
    },
    undefined,
    {
      ...defaultOpts,
      enabled: defaultOpts.enabled && !!changedModelLineId
    }
  )

  const extendedModels = useLovExtendedModelsSearchQuery(
    {
      filter: {
        ids: [changedExtendedModelId ?? ''],
        yearFromIncludeNull: true
      }
    },
    undefined,
    {
      ...defaultOpts,
      enabled: defaultOpts.enabled && !!changedExtendedModelId
    }
  )

  const fuelTypes = useLovFuelTypesQuery(undefined, defaultOpts)
  const engines = useLovEnginesSearchQuery(
    {
      filter: {
        ids: [changedEngineId ?? ''],
        yearFromIncludeNull: true
      }
    },
    {
      ...defaultOpts,
      enabled: defaultOpts.enabled && !!changedEngineId
    }
  )
  const transmissions = useLovTransmissionsQuery(undefined, {
    ...defaultOpts,
    enabled: defaultOpts.enabled && !!changedTransmissionId
  })
  const colors = useLovColorsQuery(undefined, {
    ...defaultOpts,
    enabled: defaultOpts.enabled && !!changedColorId
  })

  const drives = useLovDrivesQuery(undefined, defaultOpts)

  const emissionNormList = useEmissionNormList()

  const parameterDiffs = useMemo(() => {
    if (!!diffs.length) {
      return diffs.map(item => {
        // CAR PARAMETERS
        if (item.propertyName === '$.car.bodyId') {
          return {
            ...item,
            oldValue: getMappedLOVValue(bodies.data ?? [], item)?.name
          }
        }

        if (item.propertyName === '$.car.firstRegistrationDate') {
          return {
            ...item,
            oldValue: item.oldValue
              ? t('auction:detail_parameters_year_registration_value', {
                  date: new Date(item?.oldValue)
                })
              : undefined
          }
        }

        if (item.propertyName === '$.car.modelLineId') {
          return {
            ...item,
            oldValue: getMappedLOVValue(modelLines.data?.content ?? [], item)
              ?.name
          }
        }

        if (item.propertyName === '$.car.colorId') {
          return {
            ...item,
            oldValue: getMappedLOVValue(colors.data?.content ?? [], item)?.name
          }
        }

        if (item.propertyName === '$.car.makeId') {
          return {
            ...item,
            oldValue: getMappedLOVValue(makes.data?.content ?? [], item)?.name
          }
        }

        if (item.propertyName === '$.car.speedometerMileageKm') {
          return {
            ...item,
            oldValue: t('auction:detail_parameters_mileage_value', {
              value: item.oldValue
            })
          }
        }

        if (item.propertyName === '$.car.modelId') {
          return {
            ...item,
            oldValue: getMappedLOVValue(models.data?.content ?? [], item)?.name
          }
        }

        if (item.propertyName === '$.car.extendedModelId') {
          return {
            ...item,
            oldValue: getMappedLOVValue(
              extendedModels.data?.content ?? [],
              item
            )?.name
          }
        }

        if (item.propertyName === '$.car.transmissionId') {
          return {
            ...item,
            oldValue: getMappedLOVValue(transmissions.data?.content ?? [], item)
              ?.name
          }
        }

        if (item.propertyName === '$.car.serviceBook.lastServicingDate') {
          return {
            ...item,
            oldValue: item.oldValue
              ? t('auction:detail_parameters_service_book_date_value', {
                  date: new Date(item.oldValue)
                })
              : undefined
          }
        }

        if (item.propertyName === '$.car.serviceBook.lastMileage') {
          return {
            ...item,
            oldValue: t('auction:detail_parameters_mileage_value', {
              value: item.oldValue
            })
          }
        }

        if (item.propertyName === '$.car.fuelTypeId') {
          return {
            ...item,
            oldValue: getMappedLOVValue(fuelTypes.data?.content ?? [], item)
              ?.name
          }
        }

        if (item.propertyName === '$.car.engineId') {
          const engine = getMappedLOVValue(engines.data?.content ?? [], item)

          return {
            ...item,
            oldValue: [
              engine?.name,
              t('auction:detail_parameters_engine_power_value', {
                value: engine?.powerKw
              })
            ].join(' ')
          }
        }

        if (item.propertyName === '$.car.driveId') {
          return {
            ...item,
            oldValue: getMappedLOVValue(drives.data ?? [], item)?.name
          }
        }

        if (item.propertyName === '$.car.towbar') {
          return {
            ...item,
            oldValue: towbarState(
              item.oldValue as CarDetailMapped['car']['towbar'],
              t
            )
          }
        }

        if (item.propertyName === '$.car.vatDeductible') {
          return {
            ...item,
            oldValue: t(`core:${item?.oldValue}` as `core:${'true' | 'false'}`)
          }
        }

        if (item.propertyName === '$.car.metallicColor') {
          return {
            ...item,
            oldValue: t(`core:${item?.oldValue}` as `core:${'true' | 'false'}`)
          }
        }

        if (
          item.propertyName === '$.car.technicalInspectionValidityUntilDate'
        ) {
          return {
            ...item,
            oldValue: item.oldValue
              ? t('auction:detail_parameters_technical_inspection_value', {
                  date: new Date(item.oldValue)
                })
              : t('core:false')
          }
        }

        if (item.propertyName === '$.car.factoryWarrantyUntilMileageKm') {
          return {
            ...item,
            oldValue: item.oldValue
              ? t('core:km_format', { km: item.oldValue })
              : t('core:not_yet_detected')
          }
        }

        if (item.propertyName === '$.car.factoryWarrantyUntilDate') {
          return {
            ...item,
            oldValue: item.oldValue
              ? t('auction:detail_parameters_factory_warranty_up_to_value', {
                  date: new Date(item.oldValue)
                })
              : t('core:not_yet_detected')
          }
        }

        if (item.propertyName === '$.car.emissionNormId') {
          return {
            ...item,
            oldValue: getMappedLOVValue(
              emissionNormList.emissions.map(emission => ({
                id: emission.value,
                label: emission.label
              })),
              item
            )?.label
          }
        }

        if (item.propertyName === '$.car.co2EmissionsGramsPerKm') {
          return {
            ...item,
            oldValue: item.oldValue
              ? t('auction:detail_parameters_co2_value', {
                  value: item.oldValue
                })
              : undefined
          }
        }

        if (item.propertyName === '$.wheels.extraSetOfTires') {
          return {
            ...item,
            oldValue: t(`core:${item?.oldValue}` as `core:${'true' | 'false'}`)
          }
        }

        return item
      })
    }

    return []
  }, [
    bodies.data,
    modelLines.data?.content,
    colors.data?.content,
    makes.data?.content,
    models.data?.content,
    engines.data?.content,
    extendedModels.data?.content,
    fuelTypes.data?.content,
    transmissions.data?.content,
    drives.data,
    emissionNormList.emissions,
    t,
    diffs
  ])

  return parameterDiffs
}
