import { URLS } from 'constants/api'
import { paths } from 'driverama-core/api/driverama/generated/auctions'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { useQuery } from 'react-query'
import { apiAuthFetcher } from 'utils/authFetcher'
import { QUERY_KEYS } from '../../../constants/queryKeys'

type AuctionDiffResponse = paths['/auctions/{auctionId}/diff']['post']['responses']['200']['content']['application/com.driverama-v1+json']

export type AuctionDiff = AuctionDiffResponse[number]

export interface TypedAuctionDiff<T> extends Omit<AuctionDiff, 'propertyName'> {
  propertyName: T
}

export function useAuctionDiff(
  auctionId?: string,
  previousAuctionId?: string,
  isInHistory?: boolean
) {
  return useQuery(
    QUERY_KEYS.auctionDiff(auctionId, { isInHistory, previousAuctionId }),
    async () => {
      const res = await apiAuthFetcher<AuctionDiffResponse>(
        URLS.auctionDiff(auctionId),
        {
          method: HTTPMethod.POST,
          body: {
            previousAuctionId: previousAuctionId ?? ''
          }
        }
      )
      return res.json
    },
    {
      refetchOnWindowFocus: false,
      enabled:
        driverama.flags.IS_AUCTION_DIFF_ENABLED &&
        !!auctionId &&
        !!previousAuctionId &&
        !isInHistory
    }
  )
}
