import { Flex } from 'driverama-core/components/Flex'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextBody } from 'driverama-core/components/text/Text'
import { useDetectPrint } from 'driverama-core/utils/print'
import { TechnicalDetailsDiff } from 'sections/auctions/detail/diff/technicalDetails/TechnicalDetails.utils'
import { resolvePrintUrl } from 'sections/auctions/detail/gallery/AuctionsDetailGallery.utils'
import {
  SDot,
  SImage,
  SImageContainer,
  SListItem,
  SMessageIcon,
  SPrintImage
} from './AuctionsDetailTechnicalItemContent.styled'
import { useTechnicalItems } from './AuctionsDetailTechnicalItemContent.utils'

type Props = {
  item: NonNullable<ReturnType<typeof useTechnicalItems>['data']>[number]
  openModal: () => Promise<void>
}

export function DamagedItem({ item, openModal }: Props) {
  const isPrinting = useDetectPrint()

  return (
    <>
      {item.photos.map(photo => (
        <SImageContainer key={photo.id} onClick={openModal}>
          <SImage src={photo.url} />
          {isPrinting && <SPrintImage src={resolvePrintUrl(photo.url)} />}
        </SImageContainer>
      ))}

      <DamageDescriptionItemWithDiff
        name={item.name}
        note={item.note}
        diff={item.diff}
      />
    </>
  )
}

type ItemWithDiffProps = {
  name?: string
  note?: string
  diff?: TechnicalDetailsDiff
}

function DamageDescriptionItemWithDiff({
  name,
  note,
  diff
}: ItemWithDiffProps) {
  return (
    <Flex variant="row">
      {diff?.changeType === 'VALUE_CHANGED' ? (
        <Flex variant="column">
          {[diff?.note, diff?.name, diff?.customItemDescription].some(
            Boolean
          ) && (
            <>
              <DamageDescriptionItem
                changeType="VALUE_REMOVED"
                type="name"
                value={(diff.name || diff.customItemDescription) ?? name}
              />

              <DamageDescriptionItem
                changeType="VALUE_REMOVED"
                type="note"
                value={diff.note ?? note}
              />
              <Spacer size={2} axis="vertical" />
            </>
          )}

          <DamageDescriptionItem
            changeType="VALUE_CHANGED"
            type="name"
            value={name}
          />

          <DamageDescriptionItem
            changeType="VALUE_CHANGED"
            type="note"
            value={note}
          />
        </Flex>
      ) : (
        <Flex variant="column">
          <DamageDescriptionItem
            changeType={diff?.changeType}
            type="name"
            value={name}
          />
          <DamageDescriptionItem
            changeType={diff?.changeType}
            type="note"
            value={note}
          />
        </Flex>
      )}
    </Flex>
  )
}

function DamageDescriptionItem({
  value,
  type,
  changeType
}: {
  value?: string
  type: 'note' | 'name'
  changeType?: 'VALUE_CHANGED' | 'VALUE_ADDED' | 'VALUE_REMOVED'
}) {
  const Icon = type === 'name' ? SDot : SMessageIcon
  const color =
    changeType === 'VALUE_REMOVED'
      ? 'night-text-light'
      : changeType === 'VALUE_ADDED'
      ? 'black'
      : 'alert'

  if (!value) {
    return null
  }

  return (
    <SListItem changeType={changeType}>
      <Icon />
      <TextBody color={color}>{value}</TextBody>
    </SListItem>
  )
}
