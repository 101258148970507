import { css } from '@emotion/react'
import { StrikeTextBody } from 'components/diff/StrikeTextBody'
import { StrikeTextBodyBold } from 'components/diff/StrikeTextBodyBold'
import { Flex } from 'driverama-core/components/Flex'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { Tag } from 'driverama-core/components/tag/Tag'
import { TextBody, TextBodyBold } from 'driverama-core/components/text/Text'
import IconWarningFull from 'driverama-core/images/icons/IconWarningFull.svg'
import { media } from 'driverama-core/styles/media'
import { useTranslation } from 'react-i18next'
import { SToolTip } from './Parameters.styled'
import { ParameterItemWithDiff } from './Parameters.utils'

export function ParametersItem({
  label,
  value,
  diff,
  warning,
  warningTriggered
}: ParameterItemWithDiff) {
  const { t } = useTranslation(['core', 'auction'])

  if (diff?.changeType === 'VALUE_CHANGED') {
    return (
      <>
        <TextBody
          css={css`
            min-width: 150px;
          `}
        >
          {label}
        </TextBody>

        <Flex
          variant={['column', 'row']}
          align={['end', 'center']}
          gap={2}
          css={css`
            margin-left: auto;
          `}
        >
          <StrikeTextBodyBold
            css={css`
              text-align: right;
            `}
          >
            {diff?.oldValue}
          </StrikeTextBodyBold>
          <TextBodyBold
            css={css`
              text-align: right;

              @media ${media.lte('SM')} {
                order: -1;
              }
            `}
          >
            {value}
          </TextBodyBold>
        </Flex>
      </>
    )
  }

  if (diff?.changeType === 'VALUE_ADDED') {
    return (
      <>
        <Flex variant="row" align="center">
          <TextBody>{label}</TextBody>
          <Spacer size={2} axis="horizontal" />
          <Tag variant="dark">{t('auction:diff_value_added_label')}</Tag>
        </Flex>

        <TextBodyBold
          css={css`
            text-align: right;
          `}
        >
          {value}
        </TextBodyBold>
      </>
    )
  }

  if (diff?.changeType === 'VALUE_REMOVED') {
    return (
      <>
        <StrikeTextBody>{label}</StrikeTextBody>
        <StrikeTextBodyBold
          css={css`
            text-align: right;
          `}
        >
          {diff?.oldValue}
        </StrikeTextBodyBold>
      </>
    )
  }

  return (
    <>
      <TextBody>{label} </TextBody>

      <TextBodyBold
        css={css`
          text-align: right;
        `}
      >
        <Flex variant="row" gap={2}>
          <span>{value || '-'}</span>

          {warningTriggered && (
            <SToolTip
              maxWidth="107px"
              content={warning || t('core:suspicious_value')}
              placement="bottom"
            >
              <IconWarningFull />
            </SToolTip>
          )}
        </Flex>
      </TextBodyBold>
    </>
  )
}
