import { Flex } from 'driverama-core/components/Flex'
import { SCount, SNumber } from './AuctionsDetailTechnicalItem.styled'

type Props = {
  problematicCount: number
  hasAdded: boolean
  oldValue?: string | null
}

export function ProblematicItemsCount({
  problematicCount,
  oldValue,
  hasAdded
}: Props) {
  return (
    <Flex variant="row" gap={1}>
      {!!oldValue && (
        <SCount isDiff>
          <SNumber size="small">{oldValue}</SNumber>
        </SCount>
      )}

      <SCount hasAdded={hasAdded}>
        <SNumber size="small">{problematicCount}</SNumber>
      </SCount>
    </Flex>
  )
}
