import { useLovTechnicalSectionsQuery } from 'api/driverama/lov/lovTechnicalSections'
import { useMemo } from 'react'
import { CarDetailMapped } from '../AuctionsDetail.utils'

export function useSections(
  carTechnicalSections: CarDetailMapped['technicalConditionSections']
) {
  const opts = {
    enabled: !!carTechnicalSections.sections.length,
    refetchOnWindowFocus: false
  }

  const { isLoading, isError, data: lovData } = useLovTechnicalSectionsQuery(
    undefined,
    opts
  )

  const data = useMemo(() => {
    if (!!carTechnicalSections && !!lovData?.content?.length) {
      return carTechnicalSections.sections
        .sort((a, b) => a.sectionId.localeCompare(b.sectionId))
        .map(section => {
          const lovSection = lovData?.content.find(
            item => item.id === section.sectionId
          )
          return {
            sectionId: section.sectionId,
            lovSectionId: lovSection?.id,
            name: lovSection?.name
          }
        })
    }
    return undefined
  }, [lovData, carTechnicalSections])

  return {
    data,
    isLoading,
    isError
  }
}
