import { ImagesGallery } from 'driverama-core/components/imagesGallery/ImagesGallery'
import {
  imageGalleryModalBackgroundStyles,
  imageGalleryModalStyles
} from 'driverama-core/components/imagesGallery/ImagesGalleryModalStyles'
import { useModal } from 'driverama-core/components/modal/Modal'
import { Spinner } from 'driverama-core/components/spinner/Spinner'
import { TextBody } from 'driverama-core/components/text/Text'
import { media } from 'driverama-core/styles/media'
import { useDetectPrint } from 'driverama-core/utils/print'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAsyncFn, useMedia } from 'react-use'
import {
  downloadImagesZip,
  resolvePrintUrl,
  useCarImages
} from 'sections/auctions/detail/gallery/AuctionsDetailGallery.utils'
import { logEvent } from 'utils/analytics'
import { CarDetailMapped } from '../AuctionsDetail.utils'
import {
  SContainer,
  SImage,
  SImageButton,
  SImageContainer,
  SImageContainerOverlay,
  SImageOverlay,
  SLoader,
  SPrintImage
} from './AuctionsDetailGallery.styled'

const DEFAULT_PHOTOS_TO_SHOW = 3

export function AuctionsDetailGallery({
  carId,
  carMedia,
  auctionId,
  carTitle,
  carYearOfMake
}: {
  carId: string
  carMedia: CarDetailMapped['media']
  auctionId: string | undefined
  carTitle?: string
  carYearOfMake?: number
}) {
  const isLaptop = useMedia(media.lte('laptop'))
  const isMD = useMedia(media.lte('MD'))
  const [imageIndex, setImageIndex] = useState<number | undefined>()
  const { t } = useTranslation(['core', 'auction'])
  const isPrinting = useDetectPrint()

  const { data, isLoading, isError } = useCarImages(carId, carMedia)

  const [downloadState, onDownloadAll] = useAsyncFn(downloadImagesZip, [
    carMedia
  ])

  const displayedPhotosCount = isMD ? 1 : isLaptop ? 2 : DEFAULT_PHOTOS_TO_SHOW

  const logImageView = () => {
    if (auctionId) {
      logEvent('photo_viewed', {
        auction_id: auctionId
      })
    }
  }

  const [modal, openModal] = useModal(
    () => (
      <ImagesGallery
        logImageView={logImageView}
        images={data || []}
        openIndex={imageIndex}
        showFullImg
      />
    ),
    {
      wrapperStyles: imageGalleryModalStyles,
      backgroundStyles: imageGalleryModalBackgroundStyles,
      closeLabel: t('core:close')
    }
  )

  const handleOpen = (i?: number) => {
    setImageIndex(i)
    openModal()
  }

  return (
    <>
      {isLoading ? (
        <SLoader>
          <Spinner />
        </SLoader>
      ) : isError || !data ? (
        <SLoader>
          <TextBody>{t('core:error_api')}</TextBody>
        </SLoader>
      ) : (
        <SContainer>
          {data?.map((item, i) => {
            const isLastVisible =
              i + 1 === displayedPhotosCount ||
              (data?.length < displayedPhotosCount && i + 1 === data?.length)

            const printUrl = resolvePrintUrl(item.url)

            return (
              <SImageContainer
                onLoad={logImageView}
                key={i}
                isClickable={true}
                css={{
                  display: i + 1 > displayedPhotosCount ? 'none' : 'block'
                }}
                onClick={isLastVisible ? undefined : () => handleOpen(i)}
              >
                {isLastVisible && (
                  <SImageContainerOverlay
                    variant="column"
                    justify="center"
                    align="center"
                    gap={4}
                  >
                    <SImageOverlay />

                    <SImageButton
                      variant="outline"
                      onClick={event => {
                        event.currentTarget.blur()
                        openModal()
                      }}
                    >
                      {t('auction:detail_gallery_all_photos')}
                    </SImageButton>

                    <SImageButton
                      disabled={downloadState.loading}
                      variant="outline"
                      onClick={() =>
                        onDownloadAll(data, carTitle, carYearOfMake)
                      }
                    >
                      {downloadState.loading
                        ? t('auction:detail_gallery_download_preparing')
                        : t('auction:detail_gallery_download_all')}
                    </SImageButton>
                  </SImageContainerOverlay>
                )}
                <SImage src={item.url} />
                {isPrinting && <SPrintImage src={printUrl} />}
              </SImageContainer>
            )
          })}
        </SContainer>
      )}
      {modal}
    </>
  )
}
