import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { paths } from 'driverama-core/api/driverama/generated/files'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { useQuery, UseQueryOptions } from 'react-query'
import { apiAuthFetcher } from 'utils/authFetcher'

type FilesSearchResponse =
  | paths['/admin/files/search']['post']['responses']['200']['content']['application/com.driverama-v1+json']
  | undefined
type FilesSearchBody = paths['/admin/files/search']['post']['requestBody']['content']['application/json']

type QueryOpts = UseQueryOptions<unknown, unknown, FilesSearchResponse>

export function useFilesSearch(body: FilesSearchBody, opts?: QueryOpts) {
  return useQuery(
    QUERY_KEYS.filesSearch(body),
    async () => {
      const res = await apiAuthFetcher<FilesSearchResponse>(URLS.filesSearch, {
        method: HTTPMethod.POST,
        body: body
      })
      return res.json
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 3600,
      ...opts
    }
  )
}
