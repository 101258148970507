import { operations } from 'api/driverama/generated/lov'
import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { apiFetcher } from 'driverama-core/utils/fetcher'
import { useQuery, UseQueryOptions } from 'react-query'

export type LovTechnicalCategoriesResponse = operations['getCategories']['responses']['200']['content']['application/com.driverama-v1+json']
type LovTechnicalCategoriesParams = operations['getCategories']['parameters']['query']
type LovTechnicalCategoriesBody = operations['getCategories']['requestBody']['content']['application/json']
type LovTechnicalCategoriesOpts = UseQueryOptions<
  unknown,
  unknown,
  LovTechnicalCategoriesResponse
>

export function useLovTechnicalCategoriesQuery(
  body?: LovTechnicalCategoriesBody,
  searchParams?: LovTechnicalCategoriesParams,
  opts?: LovTechnicalCategoriesOpts
) {
  return useQuery(
    QUERY_KEYS.lovTechnicalCategories(searchParams, body),
    async () => {
      const res = await apiFetcher<LovTechnicalCategoriesResponse>(
        URLS.lovTechnicalCategories,
        { method: HTTPMethod.POST, body, searchParams }
      )
      return res.json
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      ...opts
    }
  )
}
