import { AuctionDiff } from 'api/driverama/auctions/diff'
import { useLovTechnicalSectionsQuery } from 'api/driverama/lov/lovTechnicalSections'
import { useMemo } from 'react'
import { CarDetailMapped } from '../AuctionsDetail.utils'

export function useConditionSections(
  carId: string,
  carTechnicalSections: CarDetailMapped['technicalConditionSections'],
  diffs: AuctionDiff[]
) {
  const opts = { enabled: !!carId, refetchOnWindowFocus: false }

  const {
    data: lovSectionsData,
    isLoading,
    isError
  } = useLovTechnicalSectionsQuery(undefined, opts)

  const data = useMemo(() => {
    if (!!carTechnicalSections && !!lovSectionsData) {
      return carTechnicalSections.sections
        ?.sort((a, b) => a.sectionId.localeCompare(b.sectionId))
        .map(section => ({
          sectionId: section.sectionId,
          rating: section.rating,
          name: lovSectionsData?.content.find(
            lovSection => lovSection.id === section.sectionId
          )?.name,
          diff: diffs.find(diff => diff.propertyName.includes(section.id))
        }))
    }
    return undefined
  }, [carTechnicalSections, lovSectionsData, diffs])

  return {
    data,
    isLoading,
    isError
  }
}
