import { URLS } from 'constants/api'
import { paths } from 'driverama-core/api/driverama/generated/cars'
import { toast } from 'driverama-core/components/toast/Toast'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { apiAuthFetcher } from 'driverama-core/utils/fetcher'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

type EscalateResponse = paths['/admin/cars/opportunities/{opportunityId}/escalate']['post']['responses']['202']

async function escalate(opportunityId?: string): Promise<EscalateResponse> {
  return await apiAuthFetcher(URLS.escalate(opportunityId), {
    method: HTTPMethod.POST
  })
}

export function useEscalationEmailButton(opportunityId?: string) {
  const { t } = useTranslation()
  const escalationEmailQuery = useMutation(async () => {
    await escalate(opportunityId)
  })

  const sendEscalationEmail = async () => {
    try {
      await escalationEmailQuery.mutateAsync()

      toast({ type: 'success', content: t('send_escalation_email_success') })
    } catch (e) {
      toast({
        type: 'error',
        content: t('send_escalation_email_error'),
        error: null
      })
    }
  }

  return {
    ...escalationEmailQuery,
    sendEscalationEmail
  }
}
