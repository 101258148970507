import { useAuctionsSearchQuery } from 'api/driverama/auctions/searchAuctions'
import {
  AuctionSearchResponseAgg,
  AuctionSearchResponseAggItem
} from 'api/driverama/auctions/searchAuctionsAgg'
import {
  CarDetailResponse,
  useCarDetailQuery
} from 'api/driverama/cars/carDetail'
import { useLovBodiesQuery } from 'api/driverama/lov/lovBodies'
import { useLovColorsQuery } from 'api/driverama/lov/lovColors'
import { useLovCountriesQuery } from 'api/driverama/lov/lovCountries'
import { useLovDrivesQuery } from 'api/driverama/lov/lovDrives'
import { useLovEnginesSearchQuery } from 'api/driverama/lov/lovEnginesSearch'
import { useLovExtendedModelsSearchQuery } from 'api/driverama/lov/lovExtendedModelsSearch'
import {
  LovFeature,
  useLovCarFeaturesSearchQuery
} from 'api/driverama/lov/lovFeatures'
import { useLovFuelTypesQuery } from 'api/driverama/lov/lovFuelTypes'
import { useLovMakesQuery } from 'api/driverama/lov/lovMakes'
import { useLovModelLinesSearchQuery } from 'api/driverama/lov/lovModelLinesSearch'
import { useLovModelsSearchQuery } from 'api/driverama/lov/lovModelsSearch'
import { useLovTransmissionsQuery } from 'api/driverama/lov/lovTransmissions'
import { components } from 'driverama-core/api/driverama/generated/auctions'
import { useEmissionNormList } from 'driverama-core/api/driverama/lov/lovEmissionNorms'
import { unique } from 'driverama-core/utils/array'
import { parseUTCDate } from 'driverama-core/utils/date'
import { getRemainingTime } from 'driverama-core/utils/time'
import { isNotNil } from 'driverama-core/utils/types'
import { useEffect, useMemo } from 'react'
import { logEvent } from 'utils/analytics'
import { usePartnerId } from '../../../utils/partnerId'
import { sortAuctions } from '../Auctions.utils'

export type CarDetailMapped = CarDetailResponse & {
  car: CarDetailResponse['car'] & {
    make?: string
    model?: string
    bodyType?: string
    modelLine?: string
    extendedModel?: string
    fuelType?: string
    transmission?: string
    color?: string
    // wheels: CarWheelsResponse
    featuresMapped?: LovFeature[]
    originCountry?: string
    drive?: string
    engine: {
      name?: string
      powerKw?: number
      volumeCcm?: number
    }
    title: string
    emissionNormName?: string
    auction?: components['schemas']['AuctionSearchResponse']
  }
}

export type CarTechnicalCategory = CarDetailMapped['technicalConditionCategories']['categories'][number]
export type CarTechnicalSubcategory = CarDetailMapped['technicalConditionSubcategories']['subCategories'][number]

export type AuctionState = components['schemas']['AuctionSearchResponse']['state']

export function useCarDetail(id?: string, auctionState?: AuctionState) {
  const carDetailQuery = useCarDetailQuery(id, auctionState, {
    enabled: !!id,
    refetchOnWindowFocus: false
  })

  const carData = carDetailQuery.data

  const defaultOpts = {
    enabled: !!id && !!carData,
    refetchOnWindowFocus: false
  }

  const emissionNormList = useEmissionNormList()

  // LOVs
  const makes = useLovMakesQuery(undefined, defaultOpts)
  const models = useLovModelsSearchQuery(
    {
      filter: { ids: [carData?.car.modelId || ''], yearFromIncludeNull: true }
    },
    defaultOpts
  )
  const bodies = useLovBodiesQuery({ id: carData?.car.bodyId }, defaultOpts)
  const modelLines = useLovModelLinesSearchQuery(
    {
      filter: {
        ids: [carData?.car.modelLineId || ''],
        yearFromIncludeNull: true
      }
    },
    undefined,
    defaultOpts
  )

  const extendedModels = useLovExtendedModelsSearchQuery(
    {
      filter: {
        ids: [carData?.car?.extendedModelId || ''],
        yearFromIncludeNull: true
      }
    },
    undefined,
    defaultOpts
  )
  const fuelTypes = useLovFuelTypesQuery(undefined, defaultOpts)
  const engines = useLovEnginesSearchQuery(
    {
      filter: { ids: [carData?.car?.engineId || ''], yearFromIncludeNull: true }
    },
    defaultOpts
  )
  const transmissions = useLovTransmissionsQuery(undefined, defaultOpts)
  const colors = useLovColorsQuery(undefined, defaultOpts)
  const features = useLovCarFeaturesSearchQuery(
    {
      filter: {
        ids: carData?.car.features || [],
        categoryIdIncludeNull: true
      }
    },
    defaultOpts
  )
  const countries = useLovCountriesQuery(undefined, defaultOpts)
  const drives = useLovDrivesQuery(undefined, defaultOpts)

  const auction = useAuctionsSearchQuery(
    {
      carIds: [carData?.car.id].filter(isNotNil),
      carMakeIds: [],
      ids: [],
      types: [],
      states: [],
      excludedOpportunityStates: [],
      opportunityLossReasons: []
    },
    {
      enabled: !!carData?.car.id
    }
  )

  // data mapping
  const data = useMemo<CarDetailMapped | undefined>(() => {
    if (
      !!bodies.data &&
      !!carData &&
      !!colors.data &&
      !!countries.data &&
      !!drives.data &&
      !!engines.data &&
      !!extendedModels.data &&
      !!features.data &&
      !!fuelTypes.data &&
      !!makes.data &&
      !!modelLines.data &&
      !!models.data &&
      !!transmissions.data &&
      !!emissionNormList.emissions
    ) {
      const engine = engines.data?.content.find(
        item => item.id === carData?.car.engineId
      )

      const makeName = makes.data?.content.find(
        item => item.id === carData?.car.makeId
      )?.name

      const modelName = models.data?.content.find(
        item => item.id === carData?.car.modelId
      )?.name

      const title = [makeName, modelName, carData?.car.yearOfMake, engine?.name]
        .filter(str => !!str)
        .join(' ')

      return {
        ...carData,
        car: {
          ...carData.car,
          make: makeName,
          model: modelName,
          bodyType: bodies.data?.find(item => item.id === carData?.car.bodyId)
            ?.name,
          modelLine: modelLines.data?.content.find(
            item => item.id === carData.car?.modelLineId
          )?.name,
          extendedModel: extendedModels.data?.content.find(
            item => item.id === carData.car?.extendedModelId
          )?.name,
          fuelType: fuelTypes.data?.content.find(
            item => item.id === carData.car?.fuelTypeId
          )?.name,
          alternativeFuelTypeId: fuelTypes.data?.content.find(
            item => item.id === carData.car?.alternativeFuelTypeId
          )?.name,
          engine: {
            name: engine?.name,
            powerKw: engine?.powerKw,
            volumeCcm: engine?.volumeCcm
          },
          transmission: transmissions.data?.content.find(
            item => item.id === carData.car?.transmissionId
          )?.name,
          color: colors.data?.content.find(
            item => item.id === carData.car?.colorId
          )?.name,
          featuresMapped: features.data?.content.filter(item =>
            carData.car?.features?.some(feature => feature === item.id)
          ),
          originCountry: countries.data?.content.find(
            item => item.id === carData.car?.originCountryId
          )?.name,
          drive: drives.data?.find(item => item.id === carData.car?.driveId)
            ?.name,
          title,
          emissionNormName: emissionNormList.emissions.find(
            emissionId => emissionId.value === carData.car?.emissionNormId
          )?.label,
          auction: auction.data?.content.find(
            auction => auction.carId === carData.car?.id
          )
        }
      }
    }
    return undefined
  }, [
    carData,
    bodies.data,
    colors.data,
    countries.data,
    drives.data,
    engines.data,
    extendedModels.data,
    features.data,
    fuelTypes.data,
    makes.data,
    modelLines.data,
    models.data,
    transmissions.data,
    auction.data,
    emissionNormList.emissions
  ])

  const queries = [
    makes,
    models,
    bodies,
    modelLines,
    extendedModels,
    fuelTypes,
    engines,
    transmissions,
    colors,
    features,
    countries,
    drives,
    auction,
    emissionNormList,
    carDetailQuery
  ]

  return {
    data,
    isLoading: queries.some(query => query.isLoading),
    isFetching: queries.some(query => query.isFetching),
    isError: queries.some(query => query.isError)
  }
}

interface LogAuctionDetail {
  isLoading: boolean
  make?: string
  model?: string
  yearOfMake?: number
  auctionId?: string
  mode?: '15m' | '48h'
  plannedEndAt?: string | null
}

export function useLogAuctionDetail({
  isLoading,
  make,
  model,
  yearOfMake,
  auctionId,
  mode,
  plannedEndAt
}: LogAuctionDetail) {
  const partnerId = usePartnerId()

  useEffect(() => {
    if (!isLoading && make && model && yearOfMake && auctionId && mode) {
      if (plannedEndAt) {
        logEvent('auction_detail_viewed', {
          auction_id: auctionId,
          partner_id: partnerId,
          auction_type: mode === '15m' ? 'upcoming' : 'longterm',
          make_model: `${make} ${model}`,
          year: yearOfMake,
          remaining_time: getRemainingTime(plannedEndAt)
        })
      } else {
        logEvent('auction_detail_viewed', {
          auction_id: auctionId,
          partner_id: partnerId,
          auction_type: mode === '15m' ? 'upcoming' : 'longterm',
          make_model: `${make} ${model}`,
          year: yearOfMake
        })
      }
    }
  }, [
    auctionId,
    isLoading,
    make,
    mode,
    model,
    partnerId,
    plannedEndAt,
    yearOfMake
  ])
}

export function useRelatedAuctions(
  auctionDetail?: AuctionSearchResponseAggItem
) {
  const restartedAuctionsIds = auctionDetail?.previousAuctionIdsByCarId ?? []
  const previouslyAuctionedIds = auctionDetail?.previousAuctionIdsByVin ?? []

  const relatedAuctionsIds = unique([
    ...restartedAuctionsIds,
    ...previouslyAuctionedIds
  ])

  const relatedAuctionsQuery = useAuctionsSearchQuery(
    {
      ids: relatedAuctionsIds,
      carIds: [],
      carMakeIds: [],
      states: [],
      types: [],
      excludedOpportunityStates: [],
      opportunityLossReasons: []
    },
    {
      enabled: !!relatedAuctionsIds.length
    }
  )

  const restartedAuctions = relatedAuctionsQuery.data?.content
    .reduce<AuctionSearchResponseAgg['content']>((acc, cur) => {
      if (restartedAuctionsIds.includes(cur.id)) {
        acc.push(cur)
      }
      return acc
    }, [])
    .sort(sortAuctions)

  const previouslyAuctioned =
    auctionDetail &&
    relatedAuctionsQuery.data?.content
      .reduce<AuctionSearchResponseAgg['content']>((acc, cur) => {
        if (
          previouslyAuctionedIds.includes(cur.id) &&
          parseUTCDate(auctionDetail.createdAt) > parseUTCDate(cur.createdAt)
        ) {
          acc.push(cur)
        }
        return acc
      }, [])
      .sort(sortAuctions)

  return {
    relatedAuctionsQuery,
    restartedAuctions,
    previouslyAuctioned
  }
}
